<template>
  <div class="academic-years">
    <lenon-list-page
      title="Fee Projections (Current Term)"
      :columns="columns"
      :rows="feeProjections"
      :table-loading="tableLoading"
      search-placeholder="Student ID or Name"
      show-search
    >
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-12"
        >
          <b-collapse
            id="projection"
          >
            <div class="row">
              <div class="col-md-6 mb-1">
                <div
                  style="margin-top: 9px;"
                >
                  <b-form-checkbox
                    v-model="isRecurringMode"
                    @click.native="setFeeSettingToNull()"
                  >
                    Recurring Fees
                  </b-form-checkbox>
                </div>
              </div>
              <div
                v-if="isRecurringMode"
                class="col-md-6"
              >
                <lenon-select
                  v-model="selectedFeeSettingId"
                  placeholder="Select Recurring Fee"
                  :options="recurringFeeSettings"
                  label-name="title"
                  value-name="id"
                  @input="fetchRecurringFeeProjections"
                />
              </div>
              <div
                v-else
                class="col-md-6"
              >
                <lenon-select
                  v-model="selectedFeeSettingId"
                  placeholder="Select Fee"
                  :options="filteredFeeSettings"
                  label-name="title"
                  value-name="id"
                  @input="fetchFeeProjections"
                />
              </div>
              <div class="col-md-6 mb-1">
                <lenon-button
                  :loading="generating"
                  icon="ListIcon"
                  label="Generate List"
                  variant="outline-primary"
                  tool-tip-text="Generate Fee Projections"
                  @onClick="generateList()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="col-12">
          <div class="row mb-1">
            <div class="col-md-4">
              <strong>Total Expected:
                <b-badge
                  variant="light-primary"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>{{ totalExpectedAmount | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-4">
              <strong>Total Collected:
                <b-badge
                  variant="light-success"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>+{{ totalCollectedAmount | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-4">
              <strong>Total Pending:
                <b-badge
                  variant="light-danger"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>-{{ totalPendingAmount | currency }}
                </b-badge>
              </strong>
            </div>
          </div>
        </div>
        <div
          v-if="isRecurringMode"
          class="col-12 text-danger"
        >
          <div class="mb-1">
            <strong>NB: Fee projections for recurring fees may not be accurate.
              It was calculated with the assumption that all students would be present for the entire term but this may
              not always be the case.
              The figures will be optimized as you use the attendance feature available on the
              platform.</strong>

          </div>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <div
            style="margin-top: 9px;"
            class="mr-1"
          >
            <b-form-checkbox
              v-model="isRecurringMode"
              @click.native="setFeeSettingToNull()"
            >
              Recurring Fees
            </b-form-checkbox>
          </div>
          <!--          <lenon-select v-model="selectedAcademicYearId" placeholder="Academic Year" :options="academicYears"-->
          <!--            label-name="name" value-name="id" class="mr-1" />-->
          <!--          <lenon-select v-model="selectedTermId" placeholder="Select Term" :options="terms" label-name="name"-->
          <!--            value-name="id" class="mr-1" />-->
          <lenon-select
            v-if="isRecurringMode"
            v-model="selectedFeeSettingId"
            placeholder="Select Recurring Fee"
            :options="recurringFeeSettings"
            label-name="title"
            value-name="id"
            class="mr-1"
            @input="fetchRecurringFeeProjections"
          />
          <lenon-select
            v-else
            v-model="selectedFeeSettingId"
            placeholder="Select Fee"
            :options="filteredFeeSettings"
            label-name="title"
            value-name="id"
            class="mr-1"
            @input="fetchFeeProjections"
          />
          <lenon-button
            :loading="generating"
            icon="ListIcon"
            label="List"
            tool-tip-text="Generate Fee Projections"
            @onClick="generateList()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.projection
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template #expected_amount="{row}">
        {{ row.item.expected_amount | currency }}
      </template>
      <template #pending_amount="{row}">
        {{ row.item.pending_amount | currency }}
      </template>
      <template #collected_amount="{row}">
        {{ row.item.collected_amount | currency }}
      </template>
    </lenon-list-page>

  </div>
</template>

<script>

import { ValidationObserver } from 'vee-validate'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { GET_FEE_PROJECTIONS_Q, GET_RECURRING_FEE_PROJECTIONS_Q, GET_VALID_CURRENT_AY_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import { GENERATE_PROJECTION_LIST_M } from '@/graphql/mutations'
import {
  BFormCheckbox, BBadge, BCollapse, VBToggle,
} from 'bootstrap-vue'

export default {
  name: 'FeePayments',
  components: {
    LenonModal,
    LenonMultiSelect,
    LenonSelect,
    LenonListPage,
    LenonButton,
    ValidationObserver,
    BFormCheckbox,
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      isRecurringMode: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: 'Class Name',
        },
        {
          key: 'expected_amount',
          label: `Expected Amount(${this.$store.getters['auth/currency']})`,
          sortable: true,
        },
        {
          key: 'collected_amount',
          label: `Collected Amount(${this.$store.getters['auth/currency']})`,
          sortable: true,
        },
        {
          key: 'pending_amount',
          label: `Pending Amount(${this.$store.getters['auth/currency']})`,
        },
      ],
      selectedFeeSettingId: null,
      selectedAcademicYearId: null,
      selectedTermId: null,
      generating: false,
      totalExpectedAmount: 0.00,
      totalCollectedAmount: 0.00,
      totalPendingAmount: 0.00,
    }
  },
  computed: {
    monitorSettings() {
      return this.selectedAcademicYearId
          && this.selectedTermId
          && this.selectedFeeSettingId
    },
    validCurrentAy() {
      return this.$store.getters['feeProjections/validAy']
    },
    feeProjections() {
      return this.$store.getters['feeProjections/feeProjections']
    },
    previousSetting() {
      return this.$store.getters['feeProjections/settings']
    },
    feeSettings() {
      return this.$store.getters['feeSettings/feeSettings']
    },
    recurringFeeSettings() {
      return this.$store.getters['recurringFeeSettings/recurringFeeSettings']
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    filteredFeeSettings() {
      return this.feeSettings.filter(fs => {
        if (this.selectedAcademicYearId && this.selectedTermId) {
          return ((fs.academic_year_id === this.selectedAcademicYearId)
              && (fs.term_id === this.selectedTermId))
        }
        return false
      })
    },
  },
  watch: {
    validCurrentAy(val) {
      if (val) {
        this.selectedAcademicYearId = val.academic_year_id
        this.selectedTermId = val.term_id
      }
    },
    feeProjections(val) {
      this.totalExpectedAmount = 0.00
      this.totalCollectedAmount = 0.00
      this.totalPendingAmount = 0.00
      for (let i = 0; i < val.length; i++) {
        this.totalExpectedAmount += val[i].expected_amount
        this.totalCollectedAmount += val[i].collected_amount
        this.totalPendingAmount += val[i].pending_amount
      }
    },
    monitorSettings(valid) {
      if (valid) {
        this.saveSettings()
      } else {
        this.$store.commit('feeProjections/setFeeProjections', [])
      }
    },
  },
  mounted() {
    this.setSettings()
    this.getValidCurrentAy()
  },
  methods: {
    setFeeSettingToNull() {
      this.selectedFeeSettingId = null
      this.saveSettings()
    },
    saveSettings() {
      this.$store.commit('feeProjections/setSettings', {
        selectedFeeSettingId: this.selectedFeeSettingId,
        selectedAcademicYearId: this.selectedAcademicYearId,
        selectedTermId: this.selectedTermId,
        isRecurringMode: this.isRecurringMode,
      })
    },
    getValidCurrentAy() {
      this.$apollo.query({
        query: GET_VALID_CURRENT_AY_Q,
      })
        .then(res => {
          if (res.data) {
            this.$store.commit('feeProjections/setValidAy', res.data.validCurrentAy)
          }
        })
    },
    fetchRecurringFeeProjections(id) {
      if (id && this.selectedAcademicYearId && this.selectedTermId) {
        this.tableLoading = true
        this.$apollo.query({
          query: GET_RECURRING_FEE_PROJECTIONS_Q,
          variables: {
            academic_year_id: this.selectedAcademicYearId,
            term_id: this.selectedTermId,
            fee_setting_id: id,
          },
        })
          .then(res => {
            if (res.data && res.data.recurringFeeProjections) {
              this.$store.commit('feeProjections/setFeeProjections', res.data.recurringFeeProjections)
            }
          })
          .catch(err => {
            this.showError('Something went wrong, please try again')
          })
          .finally(() => {
            this.tableLoading = false
          })
      }
    },
    fetchFeeProjections(id) {
      if (id) {
        this.tableLoading = true
        this.$apollo.query({
          query: GET_FEE_PROJECTIONS_Q,
          variables: {
            fee_setting_id: id,
          },
        })
          .then(res => {
            if (res.data) {
              this.$store.commit('feeProjections/setFeeProjections', res.data.feeProjections)
            }
          })
          .catch(err => {
            this.showError('Something went wrong, please try again')
          })
          .finally(() => {
            this.tableLoading = false
          })
      }
    },
    generateList() {
      if (this.feeProjections.length > 0) {
        this.generating = true
        this.$apollo.mutate({
          mutation: GENERATE_PROJECTION_LIST_M,
          variables: {
            academic_year_id: this.isRecurringMode ? this.selectedAcademicYearId : null,
            term_id: this.isRecurringMode ? this.selectedTermId : null,
            fee_setting_id: this.selectedFeeSettingId,
          },
        })
          .then(res => {
            this.generating = false
            if (res.errors) {
              this.showError('Something went wrong, please try again')
            }
            if (res.data && !res.data.generateFeeProjection) {
              this.showError('Failed to generate list')
            } else {
              this.downloadFile(res.data.generateFeeProjection.path, res.data.generateFeeProjection.name)
            }
          })
          .catch(err => {
            this.generating = false
            this.showError('Something went wrong, please try again')
          })
      } else {
        this.showInfo('No data to generate list for')
      }
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
      this.selectedFeeSettingId = this.previousSetting?.selectedFeeSettingId
      this.isRecurringMode = this.previousSetting?.isRecurringMode
    },
  },
}
</script>
